html {
  color: #222;
  font-size: 1em;
  font-family: Lato, sans-serif;
  line-height: 1.4;
  overflow-y: scroll;
}

p {
  text-align: justify;
}

::-moz-selection,
::selection {
  background: #f29224;
  color: #fff;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
body > header {
  position: relative;
  display: flex;
  place-content: center;
  height: 80vmin;
  width: 100%;
  background-color: #8A2387;
  background-image: radial-gradient(circle at top right, #F27121, transparent),
  radial-gradient(circle at bottom left, #8A2387, transparent);
  background-size: 100% 100%;
  margin-bottom: -2rem;
}

body > header::after {
  content: '';
  clip-path: ellipse(55% 10% at 50% 100%);
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

body > header img {
  max-width: 500px;
  width: 50vw;
  object-fit: contain;
  margin: 2rem auto 4rem auto;
  pointer-events: none;
  user-select: none;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

main {
  width: 980px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

h1 {
  margin-top: 4rem;
}

a {
  color: #f29224;
  text-decoration: none;
  transition: padding 0.1s ease;
}

a:hover {
  outline: none;
  color: #efefef;
  padding-left: 5px;
}

address a:hover {
  color: #203a43;
}

.references {
  display: grid;
  grid-gap: 2em;
  grid-auto-rows: fit-content(100%);
}

.references section {
  display: flex;
  flex-direction: column;
  border-radius: 1ch;
  border: 1px solid #0f2027;
}

.references section div {
  padding: 0 1rem;
  flex-grow: 1;
  text-align: center;
  background: #fefefe;
}

.references section header {
  position: relative;
  overflow: hidden;
  border-radius: 1ch 1ch 0 0;
}

.references section header::after {
  content: '';
  clip-path: ellipse(55% 10% at 50% 100%);
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.references section header a:hover {
  padding-left: 0;
}

.references section header img {
  max-width: 100%;
  transition: transform 0.3s ease;
  object-fit: cover;
  width: 100%;
}

.references section div h3 {
  margin-top: 0.5rem;
}

.references section div p {
  text-align: center;
}

.references section:hover header img {
  transform: scale(1.05);
}

.references section div h4 {
  margin-bottom: 0;
}

.references section footer {
  border-radius: 0 0 1ch 1ch;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  position: relative;
}

.references section footer:hover {
  background-size: 150% 150%;
}

.references section footer::before {
  content: '';
  clip-path: ellipse(55% 20% at 50% 0%);
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.references section footer a {
  text-align: center;
  display: block;
  transition: background 0.2s ease, color 0.2s ease, padding 0.1s ease;
  padding: 1.5rem 0 0.75rem 0;
}

.references section footer a:hover {
  outline: none;
  color: #efefef;
  padding-left: 5px;
  background-size: 100% 100%;
}

.cta {
  background-size: cover;
  border-radius: 5ch;
  /*border: 1px solid #355c7d;*/
  margin: 5rem 0;
  background: linear-gradient(to right, #355c7d, #6c5b7b, #c06c84) no-repeat;
  background-size: 100% 100%;
  transition: background 0.2s ease, color 0.2s ease, padding 0.1s ease;
}

.cta:hover {
  background-size: 150% 150%;
}

.cta p {
  font-size: 1.5rem;
  margin: 0;
}

.cta p a {
  display: block;
  padding: 1.5rem;
  text-align: center;
}

body > footer {
  color: #cecece;
  text-align: center;
  position: relative;
  height: 200px;
  width: 100%;
  margin-top: 5rem;
  padding-top: 50px;
  background-color: #8A2387;
  background-image: radial-gradient(circle at top right, #F27121, transparent),
  radial-gradient(circle at bottom left, #8A2387, transparent);
}

body > footer::before {
  content: '';
  width: 100%;
  clip-path: ellipse(55% 25% at 50% 0%);
  background: #fff;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

body > footer div {
  padding-top: 40px;
}

body > footer ul {
  list-style: none;
  display: block;
  text-align: center;
  width: 100%;
  padding: 0;
}

body > footer ul li {
  margin: 0 1rem;
  text-align: center;
  display: inline-block;
}

body > footer ul li a,
body > footer ul li a:hover {
  padding: 0;
}

body > footer ul li img {
  width: 32px;
}

body > footer small {
  display: block;
  margin-bottom: 1rem;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-bottom: 0
  }
  50% {
    margin-bottom: 75px;
  }
  100% {
    opacity: 1
  }
}

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
  .references {
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(2, minmax(calc(50% - 2em), 1fr));
    grid-auto-rows: fit-content(50%);
  }

  main {
    padding: 0 5rem;
  }
}

@media print,
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 1.25dppx),
(min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

